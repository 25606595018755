import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogIT from "../locales/it/messages"
import Layout from "../components/layout"
import cto from "../assets/images/home/cto.png"
import cfo from "../assets/images/home/cfo.png"
import coo from "../assets/images/home/coo.png"
import ceo from "../assets/images/home/ceo.png"
import YOLO from "../assets/images/home/YOLO.png"
import Brumbrum from "../assets/images/home/Brumbrum.png"
import freedamedia from "../assets/images/home/freedamedia.png"
import { FaLink, FaMapMarkerAlt } from 'react-icons/fa';


export default ({ data }) => (
  <I18nProvider
    language="it"
    catalogs={{
      it: catalogIT,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="it"
      title="Startupedia, l'enciclopedia delle startup"
      description="Startupedia mette a disposizione tutte le informazioni necessarie sulle startup e sull'imprenditorialità con notizie e articoli relativi al mondo delle startups"
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
      <div className="wrapper mt-3">
      <div className="wrapper mt-3">
        <div className="container">
          <h1>L'Enciclopedia delle start-up e dell'imprenditorialità</h1>
          <p>Startup è una parola che deriva dall'inglese e il cui significato letterale è "setting in motion". Ma cos'è esattamente una Startup, quali tipi di Startup ci sono ... Tutte queste domande e molto altro ancora.</p>
          
          <h2 className="pt-3">Cos'è una Startup?</h2>
          <p>Il termine Startup deriva dall'inglese e la sua traduzione letterale è "start-up". Anche se non esiste una definizione ufficiale, si potrebbe dire che una Startup è un'impresa o un'iniziativa imprenditoriale con le seguenti caratteristiche:
            <ul>
              <li>si basa sulla tecnologia</li>
              <li>è scalabile</li>
              <li>opera in un ambiente di massima incertezza</li>
            </ul>
            Oltre ai punti precedenti, un'impresa in fase di avviamento si differenzia da un'impresa classica o da una PMI per il fatto che è molto dinamica e si adatta rapidamente ai cambiamenti.
            La creazione di questo tipo di aziende tecnologiche ha dato origine al concetto di "startupismo" o imprenditorialità che non è altro che tutto ciò che ruota attorno a questo mondo di creazione di imprese che rivoluzionano il settore in cui appaiono. Clicca qui per saperne di più su <Link to="/it/che-cose/startup/">cosa sono le startup</Link>.
          </p>

          <h2 className="pt-3">Ruoli e responsabilità in una Startup</h2>
          <p>Due o tre fondatori con profili diversi fanno parte della fondazione, o costituzione, di questo tipo di società. Ognuno di loro contribuisce con la propria esperienza al successo dell'azienda. Di seguito sono riportati i ruoli tipici in una Startup.</p>
          <div className="row">
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/it/che-cose/ceo-amministratore-delegato-chief-executive-officer/"><img src={ceo} alt="CEO" title="CEO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/it/che-cose/cto-chief-technology-officer/"><img src={cto} alt="CTO" title="CTO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/it/che-cose/cfo-direttore-finanziario-chief-financial-officer/"><img src={cfo} alt="CFO" title="CFO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/it/che-cose/coo-direttore-operativo-chief-operating-officer/"><img src={coo} alt="COO" title="COO" /></Link>
            </div>
          </div>
          
          <h2 className="pt-3">Lean Startup</h2>
          <p>Il Lean Startup Method è stato creato da Eric Ries e sviluppato nel suo libro "The Lean Startup". Questa metodologia viene utilizzata dalle aziende di tutto il mondo per affrontare nuove idee di business, comprendere il mercato e saperlo scalare. L'obiettivo è quello di abbreviare i cicli di sviluppo dei prodotti e di scoprire rapidamente se un modello di business proposto è praticabile. Ciò si ottiene adottando una combinazione di sperimentazione basata su ipotesi commerciali, lancio di prodotti iterativi e apprendimento convalidato. Oggi è molto difficile raccogliere fondi, soprattutto nella fase iniziale, senza comprendere e implementare la Lean Startup. Per saperne di più su <Link to="/it/che-cose/lean-startup/">cos'è la Lean Startup</Link></p>
          
          <h2 className="pt-3">Tipi di start-up per modello di business</h2>
          <p>Le start-up, e qualsiasi altro tipo di azienda, risolvono un determinato problema a un gruppo di utenti.
            A seconda del settore in cui il problema viene risolto, le start-up possono essere suddivise, tra l'altro, in:
            <ul>
              <li><strong>Startup Fintech:</strong> sviluppare la loro soluzione applicando la <Link to="/it/che-cose/fintech-tecnologia-finanziaria/">tecnologia finanziaria</Link>. Esempio: YOLO</li>
              <li><strong>Startup Foodtech:</strong> sono dedicati al miglioramento dei processi legati all'alimentazione. Esempio: Treedom</li>
              <li><strong>Startup Legaltech:</strong> il loro mercato è la legge e l'avvocatura. Esempio: Apperio </li>
              <li><strong>Startup Healhtech:</strong> si è concentrata principalmente sul settore dell'assistenza agli anziani. Esempio: Movendo Technology</li>
              <li><strong>Startup Cleantech:</strong> automatizzare i processi relativi alla pulizia dei tessuti e delle abitazioni. Esempio: NorthVolt</li>
              <li><strong>Startup Edtech:</strong> creare soluzioni innovative nel settore dell'istruzione. Esempio: Udemy</li>
              <li><strong>Startup Insurtech:</strong> offrono servizi assicurativi ai loro clienti. Esempio: Axieme</li>
              <li><strong>Startup Proptech:</strong> sviluppare una tecnologia incentrata sul settore immobiliare. Esempio: BeDimensional</li>
            </ul></p>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            
          <h2 className="pt-3">Classificazione delle start-up per paese</h2>
          Ci sono startup innovative in tutto il mondo. Ecco alcuni esempi di startup classificate per paese d'origine.
          <h3 className="pt-3">Startup Italiane</h3>
          <p>L'Italia è stata un po' lenta a creare un fiorente ecosistema di startup, ma ha iniziato a recuperare il ritardo rispetto a storie di successo come Brumbrum e Freeda Media. Quali sono le startup da seguire nel 2021?</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={YOLO} alt="YOLO" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>YOLO</strong><br/>
                  fintech <br/>
                  <FaLink /> https://yolo-insurance.com &nbsp; <FaMapMarkerAlt /> Milano <br/>
                  I clienti Yolo possono utilizzare i loro smartphone per sottoscrivere prodotti assicurativi istantanei e pay per use: ad esempio, un'assicurazione di viaggio per un'assicurazione di viaggio o sportiva o un'assicurazione telefonica
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={freedamedia} alt="freedamedia" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Freeda Media</strong><br/>
                  media; marketing <br/>
                  <FaLink /> http://www.freedamedia.com &nbsp; <FaMapMarkerAlt /> Milano <br/>
                  Marchio più caldo nei media delle startup italiane, pubblicando brevi video, interviste e articoli rivolti alle donne di Gen Z e Millennial. Una sorta di versione social-mediatica di Elle, Teen Vogue, Cosmopolitan o Man Repeller, è riuscita ad accumulare 2,7 milioni di follower Instagram e 3,3 milioni di fan su Facebook. Si dice che 90 milioni di persone hanno visto i suoi video
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={Brumbrum} alt="Brumbrum" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Brumbrum</strong><br/>
                  transporto <br/>
                  <FaLink /> https://www.brumbrum.it &nbsp; <FaMapMarkerAlt /> Rho <br/>
                  Startup dell'e-commerce automobilistico Brumbrum fa parte di una nuova ondata di aziende che stanno ripensando il processo di acquisto di un'auto. L'azienda ha due offerte principali: retail e car-as-a-service
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <h2>Ultimi Articoli del Blog</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-xl-3 mt-5 d-none">
              <img
                src="https://placehold.co/300x600"
                title="bla bla bla"
                alt="bla bla bla"
              />
              <div>&nbsp;</div>
              <img
                src="https://placehold.co/300x250"
                title="bla bla bla"
                alt="bla bla bla"
              />
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/it/blog/"><strong>Vedi Altri Articoli sulle Startup</strong></Link></p>
        {/* </div>
      </div>
      <div className="col-12" style={{backgroundColor:"#283747"}}>
              <div className="row" style={{color:"white"}}>
                <div className="container">
                  <h2 style={{color:"white",marginTop:"10px"}}>Términos Populares</h2>
                  <p style={{color:"darkgray"}}>ola ka ase</p>
                </div>
                 */}
              </div>
            </div>
   
</div>
    </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: "it" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}